import Vue from "vue";
import Router from "vue-router";
import HelloWorld from "../components/HelloWorld";
import Login from "../views/Login";
import Signin from "../views/Signin";
import Member from "../views/Member";
import Store from "../store";
import Auth0Callback from "../views/Auth0Callback";
import Vegetable from "../views/Vegetable";
import Unit from "../views/Unit";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: HelloWorld
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/signin",
      name: "Signin",
      component: Signin,
      meta: { requiresAuth: true }
    },
    {
      path: "/member",
      name: "member",
      component: Member,
      meta: { requiresAuth: true }
    },
    {
      path: "/auth0callback",
      name: "auth0callback",
      component: Auth0Callback
    },
    {
      path: "/vegetable",
      name: "vegetable",
      component: Vegetable,
      meta: { requiresAuth: true }
    },
    {
      path: "/unit",
      name: "unit",
      component: Unit,
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  // Allow finishing callback url for logging in
  if (to.matched.some(record => record.path == "/auth0callback")) {
    console.log("router.beforeEach found /auth0callback url");
    Store.dispatch("auth0HandleAuthentication");
    next(false);
  }
  // check if user is logged in (start assuming the user is not logged in = false)
  let routerAuthCheck = false;
  // Verify all the proper access variables are present for proper authorization and if variables are valid
  if (
    localStorage.getItem("access_token") &&
    localStorage.getItem("id_token") &&
    localStorage.getItem("expires_at")
  ) {
    console.log("found local storage tokens");
    //Check JWT
    const ac_token = localStorage.getItem("access_token");
    const jwt = require("jsonwebtoken");
    const signingKey = process.env.VUE_APP_AUTH0_PUBLIC_KEY;
    jwt.verify(ac_token, signingKey, { algorithm: "RS256" }, function(
      err,
      userInfo
    ) {
      if (err) {
        localStorage.removeItem("id_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_at");
      } else if (
        userInfo.iss !=
        process.env.VUE_APP_AUTH0_CONFIG_DOMAINURL + "/"
      ) {
        localStorage.removeItem("id_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_at");
      }
    });
    if (
      !to.matched.some(record => record.path == "/signin") &&
      !JSON.parse(localStorage.getItem("user_registred"))
    ) {
      router.replace("/signin");
      next(false);
    }
    // Check whether the current time is past the Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    // set localAuthTokenCheck true if unexpired / false if expired
    routerAuthCheck = new Date().getTime() < expiresAt;
  }

  // Verify all the proper access variables are present for proper authorization

  // set global ui understanding of authentication
  Store.commit("setUserIsAuthenticated", routerAuthCheck);

  // check if the route to be accessed requires authorizaton
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if user is Authenticated
    if (routerAuthCheck) {
      // user is Authenticated - allow access
      next();
    } else {
      // user is not authenticated - redirect to login
      router.replace("/");
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");
    }
  }
  // Allow page to load
  else {
    next();
  }
});

export default router;
