import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_APIURL;

class VegetableService {
  getAllVegetables() {
    return axios.get(API_URL + "/vegetables/", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }
  delVegetable(id) {
    return axios.delete(API_URL + "/vegetable/" + id, {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }
  setVegetable(data) {
    return axios
      .post(
        process.env.VUE_APP_APIURL + "/vegetable/",
        {
          name: data.name,
          price: data.price,
          unit: {
            id: data.unit
          }
        },
        { headers: authHeader(), "Content-type": "application/json" }
      )
      .then(function(response) {
        console.log(response);
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
}
export default new VegetableService();
