import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_APIURL + "/users/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getAllUsers() {
    return axios.get(API_URL, {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }

  getUser() {
    return axios.get(process.env.VUE_APP_APIURL + "/user/", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }

  setUser(data) {
    return axios
      .post(
        process.env.VUE_APP_APIURL + "/user/",
        {
          pseudo: data.pseudo,
          name: data.name,
          surname: data.surname,
          email: data.email,
          authid: data.authid,
          location: {
            lon: data.lon,
            lat: data.lat,
            lon_tr: data.lon + (Math.random() * (0.001 + 0.001) - 0.001),
            lat_tr: data.lat + (Math.random() * (0.001 + 0.001) - 0.001),
            address: data.address
          }
        },
        { headers: authHeader(), "Content-type": "application/json" }
      )
      .then(function(response) {
        console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  userExist() {
    return axios.get(process.env.VUE_APP_APIURL + "/user/exist/", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }

  getUserBoard() {
    return axios.get(API_URL + "user", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }
}
export default new UserService();
