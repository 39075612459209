<template>
  <v-container>
    <v-slide-y-transition mode="out-in">
      <v-layout row wrap align-center>
        <v-flex xs12 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
          <v-card style="margin-bottom:200px;">
            <div class="pa-3">
              LOGO GOES HERE
            </div>
            <hr />
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Welcome</h3>
                <div>
                  Member page
                  <br /><br />
                  <l-map class="map" :zoom="zoom" :center="center">
                    <l-tile-layer
                      :url="url"
                      :attribution="attribution"
                    ></l-tile-layer>
                    <l-marker :lat-lng="marker"></l-marker>
                  </l-map>
                  <v-btn @click="getUser">test</v-btn>
                  <ul>
                    {{
                      lat
                    }}
                    <li v-for="(dat, index) in content" :key="index">
                      {{ dat.Name }}
                    </li>
                  </ul>
                  {{ test }}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </div>
              </div>
            </v-card-title>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>
<script>
import UserService from "../services/user.service";
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  name: "Member",
  computed: {},
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data: () => ({
    content: "",
    test: process.env.VUE_APP_APIURL,
    lon: "",
    lat: "",
    zoom: 13,
    center: L.latLng(45.8349255, 1.33),
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    marker: L.latLng(47.41322, -1.219482)
  }),
  mounted() {
    this.getMe();
  },
  methods: {
    getTest() {
      this.test = process.env.VUE_APP_AUTH0_CONFIG_DOMAINURL;
    },
    getUser() {
      UserService.getAllUsers().then(
        response => {
          this.content = response.data;
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    },
    getMe() {
      UserService.getUser().then(
        response => {
          var me = response.data;
          console.log("ls", me.location.lat, me.location.lon);
          this.lat = me.location.lat_tr;
          this.lon = me.location.lon_tr;
          this.center = L.latLng(this.lat, this.lon);
          this.marker = L.latLng(this.lat, this.lon);
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  }
};
</script>
<style>
.map {
  min-height: 600px;
  min-width: 600px;
  z-index: 0;
}
</style>
