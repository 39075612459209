<template lang="html">
  <v-overlay v-if="$store.state.loader" :value="$store.state.loader">
    <v-progress-circular
      indeterminate
      size="64"
      color="secondary"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({}),
  methods: {}
};
</script>
