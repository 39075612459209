<template>
  <div>
    Les legumes:
    <li v-for="(data, index) in content" :key="index">
      {{ data.name }} {{ data.price }} {{ data.unit.name }}
      {{ data.unit.symbol }}

      <v-btn icon color="pink" @click="delVegetable(data.id, index)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </li>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        placeholder="Entrez un nom"
        :counter="20"
        :rules="nameRules"
        label="Nom"
        required
        id="vegetable_name"
        v-model="vegetable_name"
        name="vegetable_name"
      ></v-text-field>
      <v-text-field
        placeholder="Entrez un prix"
        :counter="5"
        type="number"
        suffix="Potacoin"
        :rules="priceRules"
        label="Prix"
        required
        id="vegetable_price"
        v-model="vegetable_price"
        name="vegetable_price"
      ></v-text-field>
      <v-select
        item-text="name"
        item-value="id"
        :items="units"
        v-model="unit_id"
      />
      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Enregistrer
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VegetableService from "../services/vegetable.service";
import UnitService from "../services/unit.service";
export default {
  name: "Vegetable",
  components: {},
  data: () => ({
    valid: true,
    content: [],
    units: [],
    unit_id: [],
    vegetable_name: "",
    vegetable_price: "",
    nameRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 20) || "Le nom doit être plus petit que 20 caractères"
    ],
    priceRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 5) || "Le prix doit être plus petit que 5 caractères"
    ]
  }),
  mounted() {
    this.getVegetables();
    this.getAllUnits();
  },
  methods: {
    validate() {
      var data = {
        name: this.vegetable_name,
        price: this.vegetable_price,
        unit: this.unit_id
      };
      if (this.$refs.form.validate()) {
        console.log(data);
        let response = VegetableService.setVegetable(data);
        const addOnList = async () => {
          const a = await response;
          if (typeof a !== "undefined") {
            this.content.push(a);
          }
        };
        addOnList();
      }
    },
    getVegetables() {
      VegetableService.getAllVegetables().then(
        response => {
          this.content = response.data;
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    },
    delVegetable(id, index) {
      VegetableService.delVegetable(id).then(
        response => {
          console.log(response);
          this.content.splice(index, 1);
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    },
    getAllUnits() {
      UnitService.getAllUnit().then(
        response => {
          this.units = response.data;
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  }
};
</script>

<style scoped></style>
