import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_APIURL;

class UnitService {
  getAllUnit() {
    return axios.get(API_URL + "/units/", {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }
  setUnit(data) {
    return axios
      .post(
        process.env.VUE_APP_APIURL + "/unit/",
        {
          name: data.name,
          symbol: data.symbol
        },
        { headers: authHeader(), "Content-type": "application/json" }
      )
      .then(function(response) {
        console.log(response);
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  delUnit(id) {
    return axios.delete(API_URL + "/unit/" + id, {
      "Content-type": "application/json",
      headers: authHeader()
    });
  }
}
export default new UnitService();
