<template>
  <div>
    Les unités:
    <li v-for="(data, index) in content" :key="index">
      {{ data.name }}
      {{ data.symbol }}
      <v-btn icon color="pink" @click="delUnit(data.id, index)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </li>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        placeholder="Entrez un nom"
        :counter="20"
        :rules="nameRules"
        label="Nom"
        required
        id="unit_name"
        v-model="unit_name"
        name="unit_name"
      ></v-text-field>
      <v-text-field
        placeholder="Entrez un symbol"
        :counter="20"
        :rules="symbolRules"
        label="Symbol"
        required
        id="unit_symbol"
        v-model="unit_symbol"
        name="unit_symbol"
      ></v-text-field>
      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Enregistrer
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import UnitService from "../services/unit.service";
export default {
  name: "Unit",
  components: {},
  data: () => ({
    valid: true,
    content: [],
    unit_name: "",
    unit_symbol: "",
    nameRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 20) || "Le nom doit être plus petit que 20 caractères"
    ],
    symbolRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 20) || "Le nom doit être plus petit que 20 caractères"
    ]
  }),
  mounted() {
    this.getAllUnits();
  },
  methods: {
    validate() {
      var data = {
        name: this.unit_name,
        symbol: this.unit_symbol
      };
      if (this.$refs.form.validate()) {
        console.log(data);
        let response = UnitService.setUnit(data);

        const addOnList = async () => {
          const a = await response;
          if (typeof a !== "undefined") {
            this.content.push(a);
          }
        };
        addOnList();
      }
    },
    delUnit(id, index) {
      UnitService.delUnit(id).then(
        response => {
          console.log(response);
          this.content.splice(index, 1);
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    },
    getAllUnits() {
      UnitService.getAllUnit().then(
        response => {
          this.content = response.data;
        },
        error => {
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  }
};
</script>

<style scoped></style>
