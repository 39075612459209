<template>
  <v-app>
    <v-app-bar app color="secondaryA">
      <div class="d-flex align-center">
        <v-img
          alt="Potatroc Logo"
          contain
          src="./assets/logo_potatroc.png"
          transition="scale-transition"
          min-width="100"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn to="/member" text>Member</v-btn>
      <v-btn to="/login" v-if="!userIsAuthorized">Login</v-btn>
      <v-menu
        v-if="userIsAuthorized"
        offset-y
        open-on-hover
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on"> Administration</v-btn>
        </template>
        <v-list>
          <v-list-item
            link
            v-for="(item, index) in items"
            :key="index"
            :to="item.link"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="logout" v-if="userIsAuthorized">Logout</v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <Loader></Loader>
  </v-app>
</template>

<script>
import Loader from "./components/loading";
import axios from "axios";

export default {
  data: () => ({
    items: [
      { title: "Légumes", link: "/vegetable" },
      { title: "Unités", link: "/unit" }
    ]
  }),
  name: "App",

  components: { Loader },
  computed: {
    userIsAuthorized: function() {
      return this.$store.state.userIsAuthorized;
    }
  },
  created() {
    axios.interceptors.request.use(
      config => {
        // Do something before request is sent
        this.$store.commit("LOADER", true);
        return config;
      },
      error => {
        // Do something with request error
        this.$store.commit("LOADER", false);

        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        console.log(response);
        this.$store.commit("LOADER", false);
        return response;
      },
      err => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        this.$store.commit("LOADER", false);
        return Promise.reject(err);
      }
    );
  },

  methods: {
    logout() {
      this.$store.dispatch("auth0Logout");
      console.log("logout");
    }
  }
};
</script>
