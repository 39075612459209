<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="id_token.email"
      :rules="emailRules"
      label="E-mail"
      disabled
    ></v-text-field>
    <v-text-field
      placeholder="Entrez un pseudo"
      :counter="10"
      :rules="pseudoRules"
      label="Pseudo"
      required
      id="pseudo"
      v-model="pseudo"
      name="pseudo"
    ></v-text-field>
    <v-text-field
      placeholder="Entrez votre nom"
      :counter="10"
      :rules="nameRules"
      label="Nom"
      required
      id="name"
      v-model="name"
      name="name"
    ></v-text-field>
    <v-text-field
      placeholder="Entrez votre prenom"
      :counter="10"
      :rules="surnameRules"
      label="Prénom"
      required
      id="surname"
      v-model="surname"
      name="name"
    ></v-text-field>
    <v-text-field
      placeholder="Entrez votre adresse"
      :counter="100"
      :rules="[adressRules.addressValidation(address)]"
      label="Adresse"
      required
      id="autocomplete"
      ref="autocomplete"
      v-model="address_field"
      name="adress"
      autocomplete="new"
    ></v-text-field>

    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
      Enregistrer
    </v-btn>
  </v-form>
</template>

<script>
import UserService from "../services/user.service";

export default {
  data: () => ({
    valid: true,
    id_token: JSON.parse(atob(localStorage.getItem("id_token").split(".")[1])),
    email: "",
    pseudo: "",
    name: "",
    surname: "",
    address: "",
    address_field: "",
    lat: "",
    lon: "",
    pseudoRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 10) ||
        "Le pseudo doit être plus petit que 10 caractères"
    ],
    nameRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 10) || "Le nom doit être plus petit que 10 caractères"
    ],
    surnameRules: [
      v => !!v || "Champ obligatoire",
      v =>
        (v && v.length <= 10) ||
        "Le prénom doit être plus petit que 10 caractères"
    ],
    adressRules: {
      addressValidation(address) {
        return v => (v == address && !!v) || "Adresse invalide";
      }
    },
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ]
  }),
  mounted() {
    /* global google */
    var options = {
      types: ["address"],
      componentRestrictions: { country: ["be", "fr"] }
    };
    var input = document.getElementById("autocomplete");
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      let ac = place.address_components;
      let resut = "";
      const adressTypes = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        sublocality_level_1: "short_name",
        country: "long_name"
      };
      if (ac) {
        for (var i = 0; i < ac.length; i++) {
          var type = ac[i].types[0];
          if (adressTypes[type]) {
            resut += ac[i][adressTypes[type]] + " ";
          }
        }
      }
      this.address = resut;
      this.address_field = resut;
      this.lat = place.geometry.location.lat();
      this.lon = place.geometry.location.lng();
      console.log(`The user picked ${this.lat}, ${this.lon}, ${this.address}`);
    });
  },
  methods: {
    validate() {
      var data = {
        email: this.id_token.email,
        pseudo: this.pseudo,
        name: this.name,
        surname: this.surname,
        authid: this.id_token.sub,
        address: this.address,
        lat: this.lat,
        lon: this.lon
      };
      if (this.$refs.form.validate()) {
        console.log(data);
        UserService.setUser(data);
      }
    }
  }
};
</script>

<style></style>
